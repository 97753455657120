"use strict";

document.addEventListener("DOMContentLoaded", function () {





  $("p:empty").remove();

  $('.service-title').text($('h1').text());
  $('input[name=service]').val($('h1').text());
  $('.callback').click(function (e) {
    e.preventDefault();
    yaCounter43672249.reachGoal('clckzakazzvonka');
    $('.callback-bg').fadeIn();
    $(".callback-bg .title-form").html("Заказать обратный звонок");
  });
  $('.order-service').click(function (e) {
    e.preventDefault();
    yaCounter43672249.reachGoal('clckzakazuslugu');
    $('.order-service-bg').fadeIn();
  });
  $(document).on('click', '.close-form', function () {
    $('.form-bg').fadeOut();
  });


  $('.resume-form form input[type=submit]').click(function (e) {
    e.preventDefault();
    var form = $(this).parents('form'),
        err = 0,
        data;
    if (!form.find('.input.email').val().length) {
      form.find('.input.email').addClass('error');
      err++;
    }
    else {
      form.find('.input.email').removeClass('error');
    }
    if (!err) {
      data = form.serialize();
      $.ajax({
        type: 'POST',
        url: "/vakansi.php",
        data: data,
        success: function (res) {
          console.log(res);
          var result = JSON.parse(res);
          $('.resume-form').text(result.message);
        }
      });
    }

  });


  //Старая форма
  $('.form-bg input[type=submit], .contact-form input[type=submit]').click(function (e) {
    e.preventDefault();
    var form = $(this).parents('form'),
        err = 0,
        data,
        goal = form.data('goal');
    form.find('input[type=text], input[type=hidden], textarea').each(function () {
      if ($(this).prop('required') && !$(this).val().length) {
        $(this).addClass('error');
        err++;
      }
      else {
        $(this).removeClass('error');
      }

    });
    if (!err) {
      data = form.serialize();
      $.ajax({
        type: 'POST',
        url: form.attr('action'),
        data: data,
        success: function (res) {
          var result = JSON.parse(res);
          console.log(result);
          if (goal) {
            yaCounter43672249.reachGoal(goal);
          }
          if ($('.form-bg:visible').length) {
            $('.form-bg:visible').children().html('<div class="close-form"></div><p>' + result.message + '</p>');
            setTimeout(function () {
              $('.form-bg').fadeOut()
            }, 3000);
          }
          else {
            $('.contact-form').text(result.message);
          }
        }
      });
    }
  });

  $('.faq-block .question').click(function () {
    $(this).siblings('.answer').slideToggle();
  });
});