"use strict";

document.addEventListener("DOMContentLoaded", () => {

  $("br").remove();

  let path = location.pathname;
  let regex = /\/uslugi\/./;
  if (regex.test(path)) {
    let h1 = $("h1").first();
    if (h1.length) {
      h1.after(`
        <ul class="list-bookmarks">
         <li>
          <a href="#our-client" class="list-bookmarks__item">Наши клиенты</a>
         </li>
         <li>
          <a href="#comments" class="list-bookmarks__item">Отзывы</a>
         </li>
         <li>
          <a href="#stages-of-work" class="list-bookmarks__item">Этапы работы</a>
         </li>
         <li>
          <a href="#contact" class="list-bookmarks__item">Контакты</a>
         </li>
         <li>
          <a href="#price" class="list-bookmarks__item list-bookmarks__item--green">Запросить стоимость</a>
         </li>
         <li>
          <a href="" class="list-bookmarks__item list-bookmarks__item--green list-bookmarks__item--form">Заказать выезд менеджера</a>
         </li>
        
      </ul>
      `)
    }

    $(".list-bookmarks a").click((e) => {
      let $this = $(e.target);
      let href = $this.attr("href");
      if (href) {
        $("html, body").animate({
          scrollTop: $(`[data-label="${href}"]`).offset().top - 150 + "px"
        });
      }
      else {
        return false;
      }
    });

    $('.list-bookmarks__item--form').click((e) => {
      $(".callback-bg").fadeIn();
      $(".callback-bg .title-form").html("Заказать выезд менеджера");
    })
  }
});

